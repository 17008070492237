.scroll-button {
  position: relative;
  z-index: 1;
  bottom: 1em;
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: transparent;
  color: #fff;
  animation: 4s linear 1s infinite normal none running MoveUpDown;
  .turn {
    font-size: 40px;
    cursor: pointer;
  }
  @keyframes MoveUpDown{
    0%, 100%{
      bottom:50px;
    }
    50%{
      bottom:100px;
    }
  }
}