.titleMessage {
  position: absolute;
  width: 100%;
  top: 22rem;
  z-index: 1;
  margin-top: -125px;
  text-align: center;
  strong {
    font-size: 1.25em;
  }
}
.heading {
  color: yellow;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  font-weight: 100;
  .main {
    font-size: 50px;
  }
  .sub {
    font-size: 27px;
    letter-spacing: 2px;
  }
}