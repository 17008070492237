.logo {
    width: 30%;
    margin-left: 34px;
}

.nav-theme {
  background-color: rgba(16,16,24,.89);
  font-size: 20px;
  height: 85px !important;
}

.navbar-collapse {
  justify-content: flex-end;
  margin-right: 2rem;
}

.navbar-dark .navbar-nav {
  .slider {
    position: absolute;
    margin-left: 0.5rem;
    width: 3rem;
    height: 46px;
    background-color: rgb(117, 54, 200);
    border-radius: 30px;
    z-index: -1;
    animation: slider 0.5s ease-in-out;
  }
  .nav-link{
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 30px;
    transition: 0.5 ease;
    &:hover {
      background-color: #fff;
      color: #2b1055;
    }
    &.active {
      background-color: rgb(117, 54, 200);
    }
  }
}

.animate-navbar {
  box-shadow: 1px 1px 1px #222;
  animation: moveDown 0.5s ease-in-out;
}

@media only screen and (max-width: 786px){
  .nav-theme{
    height: auto !important;
    width: 100%;
  }
  .logo{
  margin-bottom:-32px;
  margin-left: 22px;
  }
}

@keyframes slider {
  from {
    transform: translateX(0rem);
  }
  to {
    transform: translateX(5rem);
  }
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}
